import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ThankYou = () => (
	<Layout>
		<SEO title="Thank You Page" />
		<div className="page-hero services-page">
			<div className="hero-content">
				<h5 data-aos="fade" data-aos-duration="300" data-aos-delay="900">
					INTERMOUNTAIN BOILERWORX INC.
				</h5>
				<h1
					data-aos="fade-up"
					data-aos-duration="600"
					data-aos-delay="1200"
					className="font-weight-bolder mt-3 mt-md-5"
				>
					THANKS FOR YOUR MESSAGE
				</h1>
				<p>
					Plaease wait and we will reach out to you ASAP once we review your
					message
				</p>
				<Link
					data-aos="fade"
					className="btn btn-secondary-inverted aos-init"
					to="/"
				>
					BACK TO HOME
				</Link>
			</div>
		</div>
	</Layout>
)

export default ThankYou
